import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import prettify from "../../utils/prettify"

const PostTemplate = ({ data: { wordpressPost, pinkDiamond } }) => {
  useEffect(() => {
    prettify()
  })

  return (
    <Layout>
      <SEO
        yoastTitle={wordpressPost.yoast_title}
        yoastMeta={wordpressPost.yoast_meta}
        title={wordpressPost.title}
        description={wordpressPost.excerpt}
      />
      <div
        className={
          "bg-blue-50 bg-no-repeat bg-hero-pofo bg-hero-pofo-p lg:bg-hero-pofo-p-lg xl:bg-hero-pofo-p-xl bg-hero-pofo-s md:bg-hero-pofo-s-lg xl:bg-hero-pofo-s-xl pt-32 sm:pt-36 lg:pt-40 pb-24 lg:pb-32 px-8 sm:px-28 lg:px-28 xl:px-60 relative"
        }
      >
        <div className={"mx-auto lg:max-w-4xl"}>
          <div className={"font-serif text-black text-2xl pb-2 xl:pb-3"}>
            {wordpressPost.date}
          </div>
          <div
            className={
              "font-serif text-pink text-4xl sm:text-5xl lg:text-blog-l"
            }
            dangerouslySetInnerHTML={{ __html: wordpressPost.title }}
          ></div>
        </div>
        <img
          src={pinkDiamond.publicURL}
          className={"absolute -left-7 -bottom-10 sm:-bottom-14 w-20 sm:w-28"}
        />
      </div>
      <div className={"container mx-auto max-w-5xl pt-16 px-6"}>
        <div
          className={"pt-6 pb-24 post"}
          dangerouslySetInnerHTML={{ __html: wordpressPost.content }}
        />
      </div>
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      yoast_title
      yoast_meta {
        property
        name
        content
      }
      author {
        name
      }
    }
    pinkDiamond: file(relativePath: { eq: "bg/pink-diamond.svg" }) {
      publicURL
    }
  }
`
